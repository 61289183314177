import React from 'react';
import {Viewer,UrlTemplateImageryProvider,Credit,KmlDataSource,HeadingPitchRange, JulianDate,Rectangle, Sandcastle, Clock, ClockRange, ClockStep, ClockViewModel} from '../../node_modules/cesium';
import 'cesium/Source/Widgets/widgets.css';

import CesiumNavigation from "cesium-navigation-es6";
import { backgroundColor } from 'styled-system';


const toolBoxStyle = {
  width: '20%',
  color: 'white',
  top: '-500px',
  left: '0%',
  zIndex: 2,
  position: 'relative',
  backgroundColor: "black",
}



class CesiumContainer extends React.Component {

  divRef = React.createRef();
  viewer = null;
  entity = null;

  state = {isChecked: true};


  handleClick = () => {
    this.setState(state => ({
      isChecked: !state.isChecked
    }),
    );
    console.log(this.state.isChecked);
  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
    console.log(this.state.isChecked);
  }

  
  render() {
    return (
      <div>
        <div ref={this.divRef} style={{width:this.props.width, height:this.props.height}}/>
        <div style={toolBoxStyle}>aaa</div>
        <div id="toolbar"></div>
        <div id="toolbar2"></div>
        <div id="toolbar3"></div>
        <div id="toolbar4"></div>
        <button onClick={this.handleClick}>
        {this.state.isChecked ? 'データを消す' : 'データを見る'}
        </button>

        <button onClick={this.handlekml}>
        {this.state.isChecked ? 'kmlを消す' : 'kmlを見る'}
        </button>

      <label>
        <input type="checkbox"
          checked={this.state.isChecked}
          onChange={this.toggleChange}
        />
        Check Me!
      </label>
      </div>
    );
  }
  // render() {
  //   return <div><div id="cesiumContainer"  style={{width:"100%", height:"500px"}}></div></div>;
  // }


  componentDidMount() {




    var staTime = JulianDate.fromIso8601('1950-08-30T11:56:04+08');
    var stoTime = JulianDate.fromIso8601('2030-08-30T11:56:04+08');
    var pTime = JulianDate.fromIso8601('2010-08-30T11:56:04+08');
    var rTime = JulianDate.fromIso8601('1970-08-30T11:56:04+08');
    var qTime = JulianDate.addSeconds(pTime, -6000000, new JulianDate());
    
    var clock = new Clock({
        startTime : staTime,
        stopTime : stoTime,
        currentTime : pTime,
        clockRange : ClockRange.LOOP_STOP,
        clockStep : ClockStep.SYSTEM_CLOCK_MULTIPLIER
    });
    

    const viewer = new Viewer(this.divRef.current, {
      imageryProvider: 
      new UrlTemplateImageryProvider({
        url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
        // url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
  //      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
        credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
      }),
      baseLayerPicker: false,
      timeline : true,
      animation : false,
      fullscreenButton: false,
      homeButton: false,
      vrButton: false,
      geocoder:false,
      sceneModePicker:false,
      navigationHelpButton:false,
      infoBox : false,
      selectionIndicator : false,
      // shadows : true,
      clockViewModel: new ClockViewModel(clock)
    });

    var options = {
      camera: viewer.scene.camera,
      canvas: viewer.scene.canvas,
    };


    var dataSource1 = new KmlDataSource.load(this.props.dir,options);

    document.getElementById('toolbar').innerHTML =
    '<label><input type="checkbox" id="showCheckbox" /> Show CZML</label>';


    var checkbox = document.getElementById('showCheckbox');
    checkbox.addEventListener('change', function() {
        // Checkbox state changed.
        if (checkbox.checked) {
            // Show if not shown.
            if (!viewer.dataSources.contains(dataSource1)) {
                viewer.dataSources.add(dataSource1).show = true;
                viewer.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));
            }
        } else {
            // Hide if currently shown.
            // if (viewer.dataSources.contains(dataSource1)) {
              viewer.dataSources.removeAll();
              // viewer.dataSources.remove(dataSource1);
            // }
        }
    }, false);


    viewer.dataSources.add(dataSource1).show = true;
    viewer.zoomTo(dataSource1
      // , new HeadingPitchRange(0, -1, 2000000)
      );



/// 年度を選択するラジオボタン //////////
document.getElementById('toolbar4').innerHTML =
'<input type="radio" id="year1970" name="yearchoice" value="1970" checked /><label for="year1970">1970</label><input type="radio" id="year1990" name="yearchoice" value="1990"><label for="year1990">1990</label><input type="radio" id="year2010" name="yearchoice" value="2010"><label for="year2010">2010</label>';

var radio1 = document.getElementById('year1970');
radio1.addEventListener('click', function() {
  var aTime = JulianDate.fromIso8601('1970-10-10T11:56:04+08');
  viewer.timeline.zoomTo(aTime,JulianDate.addSeconds(aTime, 600, new JulianDate()));
  viewer.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));
}, false);

var radio2 = document.getElementById('year1990');
radio2.addEventListener('click', function() {
            var aTime = JulianDate.fromIso8601('1990-10-10T11:56:04+08');
            viewer.timeline.zoomTo(aTime,JulianDate.addSeconds(aTime, 600, new JulianDate()));
            viewer.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));
}, false);

var radio3 = document.getElementById('year2010');
radio3.addEventListener('click', function() {
  var aTime = JulianDate.fromIso8601('2010-10-10T11:56:04+08');
  viewer.timeline.zoomTo(aTime,JulianDate.addSeconds(aTime, 600, new JulianDate()));
  viewer.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));
}, false);



////////// 年度を選択するラジオボタン ///



/// KMLのON/OFFボタン //////////
    document.getElementById('toolbar2').innerHTML =
    '<input type="button" id="onoff" value="Off"/>';

    var buttona = document.getElementById('onoff');
    buttona.addEventListener('click', function() {
        let currentvalue = document.getElementById('onoff').value;
        // buttona state changed.
        if (currentvalue == "On") {
            // Show if not shown.
            document.getElementById("onoff").value="Off"
            if (!viewer.dataSources.contains(dataSource1)) {
                viewer.dataSources.add(dataSource1).show = true;
                // viewer.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));
            }
        } else {
            // Hide if currently shown.
            // if (viewer.dataSources.contains(dataSource1)) {
              document.getElementById("onoff").value="On"
              viewer.dataSources.removeAll();
              // viewer.dataSources.remove(dataSource1);
            // }
        }
    }, false);
////////// KMLのON/OFFボタン ///

/// 視点を戻すボタン //////////
    document.getElementById('toolbar3').innerHTML =
    '<input type="button" id="originview" value="初期視点"/>';

    var buttona = document.getElementById('originview');
    buttona.addEventListener('click', function() {
                viewer.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));
    }, false);
////////// 視点を戻すボタン ///

    



    // var dataSourcePromise = viewer.dataSources
    // .add(KmlDataSource.load(this.props.dir,options))

    // viewer.zoomTo(dataSourcePromise, new HeadingPitchRange(0, -1, 50000));




    // .then(function (dataSource) {
    //   viewer.clock.shouldAnimate = false;})
    ;
    
    // var dataSourcePromise = viewer.dataSources.add(KmlDataSource.load(this.props.dir,options));



  



    viewer._cesiumWidget._creditContainer.style.display = "none";



/// Navigation /////
var navoptions = {};
navoptions.defaultResetView = Rectangle.fromDegrees(122, 20, 153, 45);
navoptions.enableCompass = true;
navoptions.enableZoomControls = true;
navoptions.enableDistanceLegend = true;
navoptions.enableCompassOuterRing = true;
CesiumNavigation(viewer, navoptions);
///// Navigation ///

  }
 
  componentWillUnmount() {
    this.viewer.destroy();
  }


}

export default CesiumContainer

